import React, { Component } from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import DPADNewsBanner from "../components/DPADNewsBanner"
import { graphql } from "gatsby"

interface Props {
  data: any
}

class DPADNews extends Component<Props> {
  render() {
    const { data } = this.props
    return (
      <Layout
        headerElement={<DPADNewsBanner title="All DPAD News" />}
        scrollStepInPx={50}
        delayInMs={2}
      >
        <SEO title="DPAD News" />
        <section className="content background-white p-t-150 p-b-150">
          <div className="container">
            <div className="row">
              {data?.allWordpressAcfDpadNews?.edges?.map(({ node }: any) => (
                <div
                  key={node.acf.title}
                  className="col-md-6 col-sm-6 col-xs-6 col-lg-4 pb-5"
                >
                  <div className="post-item border">
                    <div className="post-item-wrap">
                      <div className="post-image">
                        <a href={`/news/${node.acf.title}`}>
                          <img
                            src={
                              node.acf.image.localFile.childImageSharp.fluid
                                .src ?? ""
                            }
                            alt={node.acf.title}
                            style={{ position: "static", height: "232px" }}
                          />
                        </a>
                      </div>
                      <div className="post-item-description">
                        <span className="post-meta-date">
                          <i className="fa fa-calendar-o"></i>
                          {node.acf.date}
                        </span>
                        <a href={`/news/${node.acf.title}`}>
                          <h2
                            style={{
                              fontSize: "1.357143em",
                              paddingBottom: 14,
                            }}
                          >
                            {node.acf.title}
                          </h2>
                        </a>
                        <p>{node.acf.short_description}</p>
                        <a
                          href={`/news/${node.acf.title}`}
                          className="item-link"
                        >
                          Read More <i className="fa fa-chevron-right"></i>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </section>
      </Layout>
    )
  }
}

export default DPADNews

export const pageQuery2 = graphql`
  query DNewsQuery {
    allWordpressAcfDpadNews {
      edges {
        node {
          id
          acf {
            title
            date
            slug
            full_description
            short_description
            image {
              localFile {
                childImageSharp {
                  fluid {
                    srcSetWebp
                    srcSet
                    src
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
